import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Apple from '../../assets/images/apple.png'
import Google from '../../assets/images/google.png'

export class FooterDesktop extends Component {
    render() {
        return (
            <Fragment>
                <div className="footerback m-0 mt-5 pt-3 shadow-sm">
                <Container>
                    <Row className="px-0 my-5">
                        <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
                            <h5 className="footer-menu-title">Office Address</h5>
                            <p>1 Bourne Drive, M40 5GA<br/>
                            Manchester, UK<br/>
                            Email:info@rshmarket.co.uk</p>
                            <h5 className="footer-menu-title">Social Link</h5>
                            <a href=""><i className="fab m-1 h4 fa-facebook"></i></a>
                            <a href=""><i className="fab m-1 h4 fa-instagram"></i></a>
                            <a href=""><i className="fab m-1 h4 fa-twitter"></i></a>
                            <a href=""><i className="fab m-1 h4 fa-linkedin"></i></a>
                            
                        </Col>
                        <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
                        <h5 className="footer-menu-title">The Company</h5>
                        <Link className="footer-link" to="/">About Us</Link><br/>
                        <Link className="footer-link" to="/">Company Profile</Link><br/>
                        <Link className="footer-link" to="/contact">contact us</Link><br/>
                        </Col>
                        <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
                        <h5 className="footer-menu-title">More Info</h5>
                        <Link className="footer-link" to="/purchase">How To Add Advertising</Link><br/>
                        <Link className="footer-link" to="/privacy">Privacy Policy</Link><br/>

                        </Col>
                        <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
                        <h5 className="footer-menu-title">Download App</h5>
                        <a href=""><img className="app-title" src={Google}/></a>
                        <a href=""><img className="app-title mt-2" src={Apple}/></a>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={true} className="text-center m-0 pt-3 pb-1 bg-dark">
                    <Container>
                        <Row>
                            <h6 className="text-white">© Copyright 2021 rshmarket.co.uk Limited. All rights reserved.</h6>
                        </Row>
                    </Container>
                </Container>
                </div>
            </Fragment>
        )
    }
}

export default FooterDesktop
