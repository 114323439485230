import React, { Component, Fragment } from "react";
import { Col, Container, Row, Card} from "react-bootstrap";
import { Link } from "react-router-dom";
export class PopularCategories extends Component {
  render() {
    return (
      <Fragment>
        <Container className="text-center" fluid={true}>
          <div className="section-title text-center mb-55">
            <h2>POPULAR CATEGORIES</h2>
            <p>Some Of Our Pupluar Categories, You May Like.</p>
          </div>
          <Row>
            <Col className="p-1" xl={2} lg={2} md={2} sm={4} xs={6}>
              <Link to="/productdetails">
              <Card className="image-box card">
                <img className="center" src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"/>
                <Card.Body>
                  <p className="product-name-on-card">Car For salee kia</p>
                  <p className="product-price-on-card">Price: £3400</p>
                </Card.Body>
              </Card>
              </Link>
            </Col>
            <Col className="p-1" xl={2} lg={2} md={2} sm={4} xs={6}>
              <Card className="image-box card">
                <img className="center" src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"/>
                <Card.Body>
                  <p className="product-name-on-card">Car For salee kia</p>
                  <p className="product-price-on-card">Price: £3400</p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" xl={2} lg={2} md={2} sm={4} xs={6}>
              <Card className="image-box card">
                <img className="center" src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"/>
                <Card.Body>
                  <p className="product-name-on-card">Car For salee kia</p>
                  <p className="product-price-on-card">Price: £3400</p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" xl={2} lg={2} md={2} sm={4} xs={6}>
              <Card className="image-box card">
                <img className="center" src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"/>
                <Card.Body>
                  <p className="product-name-on-card">Car For salee kia</p>
                  <p className="product-price-on-card">Price: £3400</p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" xl={2} lg={2} md={2} sm={4} xs={6}>
              <Card className="image-box card">
                <img className="center" src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"/>
                <Card.Body>
                  <p className="product-name-on-card">Car For salee kia</p>
                  <p className="product-price-on-card">Price: £3400</p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" xl={2} lg={2} md={2} sm={4} xs={6}>
              <Card className="image-box card">
                <img className="center" src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"/>
                <Card.Body>
                  <p className="product-name-on-card">Car For salee kia</p>
                  <p className="product-price-on-card">Price: £3400</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default PopularCategories;
