import React, { Component, Fragment } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
export class Collection extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      arrows:false,
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>
        <Container className="text-center" fluid={true}>
          <div className="section-title text-center mb-55">
            <h2>SPOTLIGHT</h2>
            <p>All Spotlight Customer</p>
          </div>
          <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
            <Slider {...settings}>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kuof5ow0/vacuum-cleaner/y/b/i/wd-4-eu-karcher-original-imag7qy6zhpfy3u8.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kuof5ow0/vacuum-cleaner/o/j/v/wd-4-eu-karcher-original-imag7qy6yvxutste.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/ku2zjww0/television/w/c/z/43path4545bl-43path4545bl-thomson-original-imag7a88xjj5y2dv.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
            </Slider>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default Collection;
