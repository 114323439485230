import React, { Component, Fragment } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";

export class Privacy extends Component {
    render() {
        return (
            <Fragment>
            <Container>
  <Row className="p-2">
    <Col
      className=" mt-2" lg={12} md={12} sm={12} xs={12} >
          <h4 className="section-title-login"> PRIVACY POLICY</h4>
          <p className="section-title-contact">
          It is a long established fact that a reader will be distracted by the readable content of a
           page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content here', making it look like readable 
            English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text
            , and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved 
            over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
          </p>
   
    </Col>
  </Row>
</Container>
    </Fragment>
        )
    }
}

export default Privacy
