import React, { Component, Fragment } from "react";
import FooterDesktop from "../components/common/FooterDesktop";
import FooterMobile from "../components/common/FooterMobile";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import Categories from "../components/home/Categories";
import Collection from "../components/home/Collection";
import HomeTop from "../components/home/HomeTop";
import HomeTopMobile from "../components/home/HomeTopMobile";
import NewAd from "../components/home/NewAd";
import PopularCategories from "../components/home/PopularCategories";
import Spotlight from "../components/home/Spotlight";

export class HomePage extends Component {
  componentDidMount(){
    window.scroll(0,0);
  }
  render() {
    return (
      <Fragment>
        <div className="Desktop">
          <NavMenuDesktop />
          <HomeTop />
        </div>
        <div className="Mobile">
          <NavMenuMobile />
          <HomeTopMobile />
        </div>
        <Categories />
        <PopularCategories />
        <Spotlight />
        <NewAd />
        <div className="Desktop">
        <FooterDesktop/>
        </div>
        <div className="Mobile">
          <FooterMobile/>
          </div>
      </Fragment>
    );
  }
}

export default HomePage;
