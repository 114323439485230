import React, { Component, Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Button  } from "react-bootstrap";
import Slider1 from "../../assets/images/navimage.png";
export class HomeSlider extends Component {
  render() {
    return (
      <Fragment>
        <div className="mb-2">
          <Card className="slider-img ">
            <Card.Img
              src={Slider1}
              style={{
                height: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              rounded
              alt="Card image"
            />
            <Card.ImgOverlay>
              <Card.Body
                style={{
                  width: "25%",
                  height: "80%",
                  margin: "0 auto",
                  backgroundColor: "#ffffff",
                  borderRadius: 10,
                }}
              >
                <Card.Title>ADVERTISING BAR</Card.Title>
                <div class="float-container pt-4">
                    <div className="float-child input-group w-100 pb-4">
                      <input
                        type="text"
                        className="form-control pb-2"
                        placeholder="Search By Item"
                      />
                      <Button type="button" className="btn site-btn">
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="float-child input-group w-100">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search By Postcode"
                      />
                      <Button type="button" className="btn site-btn">
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                  </div>
              </Card.Body>
            </Card.ImgOverlay>
          </Card>
        </div>
      </Fragment>
    );
  }
}

export default HomeSlider;
