import React, { Component, Fragment } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
export class Contact extends Component {
    render() {
        return (
            <Fragment>
                    <Container>
          <Row className="p-2">
            <Col
              className=" mt-2"
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Row className="text-center">
                <Col
                  className="card-login  d-flex justify-content-center"
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Form className="onboardForm">
                    <h4 className="section-title-login">CONTACT WITH US</h4>
                    <h6 className="section-sub-title"> Enter Your Mobikle</h6>
                     <input className="form-control m-2" type="text" placeholder="Mobile Number"/>
                    <h6 className="section-sub-title">Please Enter Your Email</h6>
                    <input className="form-control m-2" type="email" placeholder="Enter Email" />
                     <h6 className="section-sub-title"> Please Write Your Message</h6>
                     <textarea className="form-control m-2" rows="4" cols="50"  placeholder="Message"/>
                    <Button className="btn btn-st-wide m-2">Send</Button>
                  </Form>
                </Col>
                <Col className="Desktop p-0 m-0" lg={6} md={6} sm={6} xs={6}>
                    <p className="section-title-contact">
                        1 Bourne Drive, M40 5GA</p>
                    <p>
                        Manchester, UK
                    </p>
                    <p>
                        Email:info@rshmarket.co.uk
                    </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110467.04219666132!2d-2.3007955571116483!3d53.30819595750344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb0c1a4d6657d%3A0x22bc1e99e0b3a432!2s1%20Bourne%20Dr%2C%20Manchester%20M40%205GA!5e0!3m2!1sen!2suk!4v1636153747703!5m2!1sen!2suk" width="600" height="450" styles="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
            </Fragment>
        )
    }
}

export default Contact
