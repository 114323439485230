import React, { Component, Fragment } from "react";
import { Col, Container, Image, Navbar, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import MegaMenuAll from "../home/MegaMenuAll";
export class NavMenuDesktop extends Component {
  constructor() {
    super();
    this.state = {
      SideNavState: "sideNavClose",
      ContentOverState: "ContentOverlayClose",
    };
  }
  MenuBarClickHandler = () => {
    this.SideNavOpenClose();
  };
  ContentOverlayClickHandler = () => {
    this.SideNavOpenClose();
  };

  SideNavOpenClose = () => {
    let SideNavState = this.state.SideNavState;
    let ContentOverState = this.state.ContentOverState;
    if (SideNavState === "sideNavOpen") {
      this.setState({
        SideNavState: "sideNavClose",
        ContentOverState: "ContentOverlayClose",
      });
    } else {
      this.setState({
        SideNavState: "sideNavOpen",
        ContentOverState: "ContentOverlayOpen",
      });
    }
  };
  render() {
    const styleObj = {
      fontSize: 12,
      textAlign: "center",
    };
    return (
      <Fragment>
        <div className="TopSectionDown">
          <Navbar className="navbar " fixed={"top"}>
            <Container
              className="fixed-top shadow-sm p-2 mb-0 bg-white"
              fluid={"true"}
            >
              <Row>
                <Col className="mt-2 text-start" lg={5} md={5} sm={12} xs={12}>
                  <Button
                    style={styleObj}
                    className="btn bars-btn"
                    onClick={this.MenuBarClickHandler}
                  >
                    <i className="fa fa-bars"></i>
                  </Button>
                  <Link to="/">
                    <Image className="nav-logo " src={logo} />
                  </Link>
                </Col>
                <Col className="p-1 mt-1" lg={2} md={2} sm={12} xs={12}>
                 
                </Col>
                <Col className="pl-2 mt-2 text-end" lg={5} md={5} sm={12} xs={12}>
                  <Link to="/favourite" className="btn ">
                    <i className="fa h4 fa-heart heart_st"></i>
                    <sup>
                      <span className="badge text-white bg-danger">3</span>
                    </sup>
                  </Link>
                  <Link to="/notification" className="btn">
                    <i className="fa h4 fa-bell bell_st"></i>
                    <sup>
                      <span className="badge text-white bg-danger">5</span>
                    </sup>
                  </Link>
                  <a className="btn">
                    <i className="fa h4 fa-mobile-alt mobile_st"></i>
                  </a>
                  <Link to="/login" className="h4 btn">
                    <i class="fas fa-sign-in-alt"></i>
                    <br />
                    LOGIN
                  </Link>

                  <Button
                    type="button"
                    className="btn btn-success add-btn ml-4"
                  >
                    {" "}
                    <i
                      className="far fas fa-scroll mobile_st"
                      aria-hidden="true"
                    ></i>{" "}
                    &nbsp; <span>ADD NEW ADVERTISING</span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </Navbar>
        </div>
        <div className={this.state.SideNavState}>
          <MegaMenuAll />
        </div>
        <div
          onClick={this.ContentOverlayClickHandler}
          className={this.state.ContentOverState}
        ></div>
      </Fragment>
    );
  }
}

export default NavMenuDesktop;
