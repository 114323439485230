import React, { Component, Fragment } from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import MegaMenuMobile from "../home/MegaMenuMobile";
export class NavMenuMobile extends Component {
  constructor() {
    super();
    this.state = {
      SideNavState: "sideNavClose",
      ContentOverState: "ContentOverlayClose",
    };
  }
  MenuBarClickHandler=()=>{
    this.SideNavOpenClose();
  };
  ContentOverlayClickHandler=()=>{
    this.SideNavOpenClose();
  };

  SideNavOpenClose=()=>{
      let SideNavState = this.state.SideNavState;
      let ContentOverState = this.state.ContentOverState;
      if(SideNavState ==="sideNavOpen"){
          this.setState({
            SideNavState:"sideNavClose",
            ContentOverState: "ContentOverlayClose",
          })
      }else{
        this.setState({
            SideNavState:"sideNavOpen",
            ContentOverState: "ContentOverlayOpen",
          })
      }

  };
  render() {
    const styleObj = {
        fontSize: 12,
        textAlign: "center",
    }
    return (
      <Fragment>
        <div className="TopSectionDown">
          <Container className="fixed-top shadow-sm p-2 mb-0 bg-white" fluid={"true"}>
            <Row>
              <Col className="mt-3" lg={4} md={4} sm={12} xs={12}>
                <Button
                style={styleObj}
                  className="btn bars-btn"
                  onClick={this.MenuBarClickHandler}
                >
                  <i className="fa fa-bars"></i>
                </Button>
                <Link to="/">
                  <Image className="nav-logo" src={logo} />
                </Link>
                <Button className="btn add-btn" style={styleObj}>
                  <i className="far fas fa-scroll"></i>
                  &nbsp; <span >ADD ADVERTISING</span>
                </Button>
                <Link to="/login" className="h4 btn ">
                    <i class="fas fa-sign-in-alt"></i>
                    <br />
                    <small>LOGIN</small>
                  </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={this.state.SideNavState}>
         <MegaMenuMobile/>

        </div>
        <div onClick={this.ContentOverlayClickHandler}  className={this.state.ContentOverState}></div>
      </Fragment>
    );
  }
}

export default NavMenuMobile;
