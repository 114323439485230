import React, {Component, Fragment } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class NewAd extends Component {
    constructor(props){
        super(props);
        this.next=this.next.bind(this);
        this.previous=this.previous.bind(this)
        }
        next(){
            this.slider.slickNext();
        }
        previous(){
            this.slider.slickPrev();
        }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      cssEase: "linear",
    };
    return (
      <Fragment>
        <Container className="text-center" fluid={true}>
          <div className="section-title text-center mb-55">
            <h2>
              NEW AD &nbsp;
              <a className="btn btn-sm ml-2 site-left-btn" onClick={this.previous}>
                <i className="fa fa-angle-left"></i>
              </a>
              &nbsp;
              <a className="btn btn-sm ml-2 site-btn" onClick={this.next}>
                <i className="fa fa-angle-right"></i>
              </a>
            </h2>
            <p>NEW ADVERTISING</p>
          </div>
          <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
            <Slider ref={c=>(this.slider=c)} {...settings}>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kuof5ow0/vacuum-cleaner/y/b/i/wd-4-eu-karcher-original-imag7qy6zhpfy3u8.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kuof5ow0/vacuum-cleaner/o/j/v/wd-4-eu-karcher-original-imag7qy6yvxutste.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/ku2zjww0/television/w/c/z/43path4545bl-43path4545bl-thomson-original-imag7a88xjj5y2dv.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                  <img
                    className="center w-75"
                    src="https://rukminim1.flixcart.com/image/416/416/kflftzk0/mobile/h/q/u/google-pixel-4a-ga02099-in-original-imafwyzmnfxzmv5q.jpeg?q=70"
                  />
                  <Card.Body>
                    <p className="product-name-on-card">Car For salee kia</p>
                    <p className="product-price-on-card">Price: £3400</p>
                  </Card.Body>
                </Card>
              </div>
            </Slider>
          </div>
        </Container>
      </Fragment>
    );
  }
}
export default NewAd
