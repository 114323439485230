import React, { Component, Fragment } from "react";
import { Col, Container, Row, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
class Categories extends Component {
  render() {
    return (
      <Fragment>
        <Container className="text-center" fluid={true}>
          <Row>
            <Col key={1} xl={4} lg={4} md={2} sm={12} xs={12}>
              <Row>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                  <Link to="/" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                <Link to="/" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="/" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="/" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col key={1} xl={4} lg={4} md={2} sm={12} xs={12}>
              <Row>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col key={1} xl={4} lg={4} md={2} sm={12} xs={12}>
              <Row>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>

                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
                <Col className="p-0 mt-3" key={1} xl={3} lg={3} md={8} sm={6} xs={6}>
                   <Link to="#" style={{ textDecoration: "none", color: 'black', fontWeight: 'bold' }}>
                      <Image
                        className="center"
                        src="https://rukminim1.flixcart.com/flap/128/128/image/3450e4be3b9d6d3b.jpg?q=100"
                      />
                      CAR
    
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Categories;
