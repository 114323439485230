import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HomeSlider from './HomeSlider'
import MegaMenu from './MegaMenu'


class HomeTop extends Component {
    render() {
        return (
            <Fragment>
                <Container className="p-0 m-0 overflow-hidden" fluid={true} bg="light">
                    <Row>
                        <Col lg={12} md={12}sm={12}>
                           <HomeSlider/>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default HomeTop
